<template>
  <div>
    <slot></slot>
    <transition name="fade">
      <div
          v-if="!showChoice && students"
          class="d-flex justify-content-around">
        <template v-for="student in students">
          <template v-if="isStandardCourse">
            <course-cart-button
                v-if="isStudentInCartForCourse(student, course)"
                :course="course" :student="student"
                :key="`course_${course.id}_enroll_${student.id}`"
            />
            <cs-button
                v-else
                @handle-click="selectStudent(student)"
                type="primary"
                icon-left="enroll"
                title="Since you have a registration balance greater then the course fees you can enroll directly"
                :key="`course_${course.id}_enroll_${student.id}`"
            >
              Sign up {{ student.first }}
            </cs-button>
          </template>

          <course-hourly-button
              v-else
              :course="course"
              :student="student"
              :key="`course_${course.id}_enroll_${student.id}`"
          />

        </template>
      </div>
      <div v-else>
        <course-enroll-button
            :course="course"
            :student="selectedStudent"
            class="btn-block"
            @did-enroll="didEnroll"
        >
          Enroll <strong>{{ selectedStudent.first }}</strong> directly using credit
        </course-enroll-button>
        <h5 class="text-center">OR</h5>
        <course-cart-button :course="course"
                            :student="selectedStudent"
                            class="btn-block"
                            @did-add-to-cart="didAddToCart"
        >
          Add <strong>{{ selectedStudent.first }}</strong> to your cart
        </course-cart-button>
        <cs-button
            v-if="isStandardCourse"
            type="warning"
            icon-left="cancel"
            class="btn-block"
            @handle-click="showChoice = false"
        >
          Cancel
        </cs-button>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { tShirtMixin } from '@/components/mixins/tShirtMixin'
import isEmpty from 'lodash/isEmpty'

import CourseEnrollButton from '@/components/calendars_sessions/sessions/courses/CourseEnrollButton'
import CourseCartButton from '@/components/calendars_sessions/sessions/courses/CourseCartButton'
import CourseHourlyButton from '@/components/calendars_sessions/sessions/courses/CourseHourlyButton'
import { courseMethods } from '@/components/mixins/course'

// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'CourseEnrollButtons',
  mixins: [tShirtMixin, courseMethods],
  components: {
    CourseHourlyButton,
    CourseEnrollButton,
    CourseCartButton
  },
  props: {
    course: { type: Object, required: true },
    students: {
      type: [Object, Array],
      default: function () {
        return {}
      }
    },
    enrolled: {
      type: Object,
      required: false
    },
    message: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      showChoice: false,
      selectedStudent: this.students[0]
    }
  },
  created () {
    this.program_slug = this.program.slug
  },
  mounted () {},
  methods: {
    isEmpty,
    // @vuese
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    selectStudent (student) {
      this.selectedStudent = student
      this.showChoice = true
    },
    didEnroll (student, course) {
      this.showChoice = false
    },
    didAddToCart (student, course) {
      this.showChoice = false
    },
    didMount (e) {
      console.log('didMount', e)
    },
    addedToCart (payload) {
      this.checkTShirt(payload.student, payload.course)
      this.$emit('added-to-cart', payload)
    },
    enrolledCourse (payload) {
      this.checkTShirt(payload.student, payload.course)
      this.$emit('enrolled-course', payload)
    },
    checkTShirt (student, course) {
      console.log('checkTShirt', student, course, this.hasSize(student.id))
      if (course.include_t_shirt) {
        console.log('checkTShirt includes')
        if (!this.hasSize(student.id)) {
          console.log('checkTShirt includes noSize')
          this.studentForTShirt = student
          this.showModal = true
        }
      }
    },
    hideModal () {
      this.showModal = false
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'gteManager'
    }),
    isStandardCourse () {
      return this.course.hourly_fee === 0 || (!this.course.allow_start && !this.course.allow_end)
    }
  }
}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}

.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}

</style>
