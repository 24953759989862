import { render, staticRenderFns } from "./CourseWaitlistButtons.vue?vue&type=template&id=12bad936&scoped=true&"
import script from "./CourseWaitlistButtons.vue?vue&type=script&lang=js&"
export * from "./CourseWaitlistButtons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12bad936",
  null
  
)

export default component.exports