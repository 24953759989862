<template>
  <div>
    <template v-if="students && !isManager">
      <template v-for="student in studentsWhoCanEnroll">
        <div v-if="isStudentEnrolled(student)" :key="student.id">
          <button class="btn btn-outline-info disabled" disabled>
            {{ student.first }} enrolled
          </button>
        </div>
        <button
          v-else-if="inCart(student)"
          :key="student.id"
          v-on:click.prevent="deleteGroupFromCart(student, $event)"
          class="btn btn-warning"
        >
          <font-awesome-icon icon="cart-arrow-down" />
          {{ student.first }}
        </button>
        <button
          v-else
          :key="student.id"
          v-on:click.prevent="addGroupToCart(student, $event)"
          class="btn btn-success"
        >
          <font-awesome-icon icon="cart-plus" />
          {{ student.first }}
        </button>
      </template>
    </template>
    <template class="btn-group" v-if="isManager && students">
      <template v-for="student in studentsWhoCanEnroll">
        <button
          v-if="isStudentEnrolled(student)"
          :key="student.id"
          v-on:click.prevent="unenrollGroup(student, $event)"
          class="btn btn-danger"
        >
          <font-awesome-icon icon="user-minus" />
          {{ student.first }}
        </button>
        <button
          v-else
          :key="student.id"
          v-on:click.prevent="enrollGroup(student, $event)"
          class="btn btn-primary"
        >
          <font-awesome-icon icon="user-plus" />
          {{ student.first }}
        </button>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { groupMethods } from '@/components/mixins/group'

export default {
  name: 'EnrollGroupButtons',
  mixins: [groupMethods],
  props: ['group'],
  data () {
    return {
      // in_cart: []
    }
  },
  created () {},
  mounted () {},
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    isStudentEnrolled (student) {
      if (typeof this.group.courses !== 'undefined') {
        const courses = this.group.courses
        var vm = this
        const index = courses.find(function (course) {
          return !vm.isStudentEnrolledCourse(student, course)
        })
        if (typeof index === 'undefined') {
          return true
        }
        return false
      }
      return false
    },
    isStudentEnrolledCourse (student, course) {
      if (
        typeof course.students !== 'undefined' &&
        course.students.length > 0
      ) {
        const inCourse = course.students.find(function (checkStudent) {
          return checkStudent.id === student.id
        })
        if (typeof inCourse !== 'undefined') {
          return true
        }
      }
      return false
    },
    inCourseCart (student, course) {
      if (
        this.enrolledCourses !== null &&
        typeof this.enrolledCourses.sessions !== 'undefined'
      ) {
        const sessions = this.enrolledCourses.sessions
        if (typeof sessions[course.session_id] !== 'undefined') {
          const session = sessions[course.session_id]
          if (typeof session[student.id] !== 'undefined') {
            const enrolledStudent = session[student.id]
            if (typeof enrolledStudent[course.id] !== 'undefined') {
              return true
            }
          }
        }
      }
      return false
    },
    inCart (student) {
      if (typeof this.group.courses !== 'undefined') {
        const courses = this.group.courses
        var vm = this
        const inCourse = courses.find(function (course) {
          return !vm.inCourseCart(student, course)
        })
        if (typeof inCourse === 'undefined') {
          return true
        }
        return false
      }
      return false
    }
  },
  computed: {
    ...mapGetters({
      students: 'students',
      member: 'registrationMember',
      registration: 'registration',
      enrolledCourses: 'enrolledCourses',
      session: 'selectedSession',
      program: 'program',
      isManager: 'isProgramManager'
    }),
    studentsWhoCanEnroll: function () {
      return this.studentsWhoCanEnrollInGroup(this.group, this.students)
    }
  }
}
</script>

<style scoped></style>
