<template>
  <div>
    <transition name="fade">
      <div
          v-if="!showChoice && students"
          class="d-flex justify-content-around">
        <template v-for="student in students">
          <item-buy-button
              :item="item"
              :student="student"
              :key="`item_${item.id}_enroll_${student.id}`"
          />
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {UPDATE_ENROLLED_COURSES} from '@/constants/mutation-types'
import isEmpty from 'lodash/isEmpty'
import ItemBuyButton from '@/components/calendars_sessions/stores/ItemBuyButton.vue'
import {courseMethods} from '@/components/mixins/course'

// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'ItemBuyButtons',
  mixins: [courseMethods],
  components: {
    ItemBuyButton
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    students: {
      type: [Object, Array],
      default: function () {
        return {}
      }
    },
    enrolled: {
      type: Object,
      required: false
    },
    message: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      showChoice: false,
      selectedStudent: this.students[0]
    }
  },
  created() {
    this.program_slug = this.program.slug
  },
  mounted() {
  },
  methods: {
    isEmpty,
    // @vuese
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    selectStudent(student) {
      this.selectedStudent = student
      this.showChoice = true
    },
    didEnroll(student, course) {
      this.showChoice = false
    },
    didAddToCart(student, course) {
      this.showChoice = false
    },
    didMount(e) {
      console.log('didMount', e)
    },
    addedToCart(payload) {
      this.$emit('added-to-cart', payload)
    },
    enrolledCourse(payload) {
      this.$emit('enrolled-course', payload)
    },
    hideModal() {
      this.showModal = false
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'gteManager'
    })
  }
}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}

.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}

</style>
