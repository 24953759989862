<template>
  <div class="row w-100">
    <div v-for="(groups, time) in hourly" class="col-12 py-2" :key="time">
      <div v-bind:id="groupByTimeID(time)" class="card">
        <div class="card-header">Starting at {{ time | moment(" h:mma") }}</div>
        <div class="card-body">
          <div class="row">
            <div
              v-for="group in groups"
              class="col-xl-4 col-lg-6"
              :key="group.id"
            >
              <app-course-group :group="group"></app-course-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseGroup from '@/components/calendars_sessions/sessions/courses/CourseGroup'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'HourlyGroups',
  props: ['hourly'],
  components: {
    appCourseGroup: CourseGroup
  },
  methods: {
    groupByTimeID: function (time) {
      return 'group-by-time-'.concat(
        moment(time)
          .hour()
          .toString()
      )
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      session: 'selectedSession',
      registration: 'registration',
      status: 'sessionStatus',
      students: 'students',
      loggedIn: 'loggedIn'
    })
  }
}
</script>

<style scoped></style>
