<template>
  <div>
    <span v-if="enrolledStudents.length > 0" class="list-group-item">
      <h5><strong>Enrolled: </strong> {{ toList(enrolledNames) }}</h5>
    </span>
    <course-enroll-buttons v-if="showEnroll"
                           :course="course"
                           :students="unenrolledStudents"
                           class="list-group-item-primary"
    >
      <h6 class="text-center m-1">Due to <strong>credit</strong>({{ currency(registrationBalance)}}) you can enroll without adding to the cart.</h6>
    </course-enroll-buttons>
    <course-cart-buttons v-else-if="showAddToCart"
                           :course="course"
                           :students="unenrolledStudents"
                           class="list-group-item-success"
    >
      <h6 class="text-center m-1">Add {{ toList(unenrolledNames) }} to cart</h6>
    </course-cart-buttons>
    <template v-if="showWaitlist">
      <course-waitlist-buttons
          :course="course"
          :students="unenrolledStudents"
          class="list-group-item-warning"
      >
        <template v-slot:default>
          <h6 class="text-center m-1">Add to Waitlist</h6>
        </template>
        <template v-slot:no-waitlist>
          <h6>
            Class is full, contact {{ program.admin_name }} {{ program.phone }}
            <a :href="'mailto:' + program.admin_email">{{ program.admin_email }}</a>
          </h6>
        </template>
      </course-waitlist-buttons>
      <course-cart-buttons
          v-if="studentsInCart.length > 0"
          :course="course" :students="studentsInCart" class="list-group-item-success">
        <h6 class="text-center m-1">Students in cart</h6>
      </course-cart-buttons>
    </template>
    <t-shirt-picker
        v-if="false"
        :student="studentForTShirt" :course="this.course"
        :registration_id="registration.id"
        :initial_size="getSize(studentForTShirt.id)"
        @selected="studentForTShirt = null"/>
    <VDialog
        v-model="showModal"
        bg-transition="fade"
    >
      <t-shirt-picker
          v-if="studentForTShirt !== null"
          :student="studentForTShirt" :course="this.course"
          :registration_id="registration.id"
          :initial_size="getSize(studentForTShirt.id)"
          @selected="showModal = false"/>
    </VDialog>
  </div>

</template>

<script>
import CourseEnrollButtons from '@/components/calendars_sessions/sessions/courses/CourseEnrollButtons'
import CourseCartButtons from '@/components/calendars_sessions/sessions/courses/CourseCartButtons'
import CourseWaitlistButtons from '@/components/calendars_sessions/sessions/courses/CourseWaitlistButtons'
import { courseMethods } from '@/components/mixins/course'
import isEmpty from 'lodash/isEmpty'
import TShirtPicker from '@/components/t_shirts/TShirtPicker'
import { tShirtMixin } from '@/components/mixins/tShirtMixin'
import { VDialog } from 'vuetensils/src/components'
import { currency, toList } from '@/utilities/stringFormatters'

export default {
  name: 'CourseEnroll',
  mixins: [courseMethods, tShirtMixin],
  components: {
    CourseCartButtons,
    CourseEnrollButtons,
    CourseWaitlistButtons,
    TShirtPicker,
    VDialog
  },
  props: {
    course: {
      type: Object,
      required: true
    },
    students: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      studentForTShirt: null,
      courseForTShirt: null,
      showModal: false
    }
  },
  computed: {
    enrolledNames () {
      return this.enrolledStudents.map(student => {
        return student.first
      })
    },
    enrolledStudents () {
      return this.students.filter(student => {
        const enrollment = this.enrolledCourseStudent(
          this.course,
          student
        )
        return !isEmpty(enrollment)
      })
    },
    unenrolledNames () {
      return this.unenrolledStudents.map(student => {
        return student.first
      })
    },
    unenrolledStudents () {
      return this.students.filter(student => {
        const enrollment = this.enrolledCourseStudent(
          this.course,
          student
        )
        return isEmpty(enrollment)
      })
    },
    showEnroll () {
      return this.course.space > 0 &&
          this.canEnrollWithBalance &&
          this.unenrolledStudents.length > 0
    },
    showAddToCart () {
      return this.course.space > 0 &&
          this.unenrolledStudents.length > 0
    },
    studentsInCart () {
      if (isEmpty(this.unenrolledStudents)) {
        return []
      }
      return this.unenrolledStudents.filter(student => {
        console.log('CourseEnroll.studentsInCart', this.course.name, student, this.cartForStudentInCourse(student, this.course))
        return this.cartForStudentInCourse(student, this.course)
      })
    },
    showWaitlist () {
      if (this.course.space <= 1 && this.unenrolledStudents.length > 0) {
        return true
      }
      return false
    },
    canEnrollWithBalance () {
      return this.unenrolledStudents.length > 0 &&
          this.registrationBalance < 0 &&
          this.registrationBalance + this.fees <= 0
    },
    registrationBalance () {
      return this.registration.amountDue - this.registration.amountPaid
    },
    fees () {
      return this.course.fee + this.course.materialFee
    }
  },
  methods: {
    currency,
    toList
  }
}
</script>

<style scoped>

</style>
