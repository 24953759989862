<template>
  <cs-button
      v-if="inCart"
      :key="student.id"
      @handle-click="removeFromCart()"
      type="warning"
      icon-left="drop-cart"
      :loading="$wait.is(waitDelete)"
  >
    Remove {{ student.first }}
  </cs-button>
  <cs-button
      v-else-if="course.public && !blockEnrollmentWithUnpaid"
      @handle-click="addCourseToCart()"
      type="success"
      icon-left="add-cart"
      :loading="addLoading"
  >
    <slot>
      Add {{ student.first }}
    </slot>

  </cs-button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import { calendarMethods } from '@/components/mixins/calendar'
import { isEmpty, isNull } from 'lodash/core'
import moment from 'moment'

// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'CourseCartButton',
  mixins: [courseMethods, calendarMethods],
  props: {
    student: {
      type: Object,
      required: true
    },
    course: {
      type: Object,
      required: true
    },
    bulk: {
      type: Boolean,
      required: false,
      default: false
    },
    enrolled: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      studentSelections: []
    }
  },
  created () {
    this.program_slug = this.program.slug
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      students: 'students',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'gteManager',
      blockEnrollmentWithUnpaid: 'blockEnrollmentWithUnpaid',
      status: 'sessionStatus'
    }),
    addLoading () {
      if (this.course.space <= this.course.space_warning_ct) {
        return this.$wait.is([this.waitAdd, 'session.get' + this.session.id, 'registrations.get.' + this.registration.id])
      }
      return this.$wait.is(this.waitAdd)
    },
    isStudentEnrolledEmit () {
      if (this.isStudentEnrolled) {
        if (!this.course.public) {
          this.$emit('privateEnrolled')
        }
        return true
      } else {
        return false
      }
    },
    isDropped () {
      const enrolled = this.enrolled_course
      if (!isEmpty(enrolled) && enrolled.dropped) {
        return true
      } else {
        return false
      }
    },
    waitAdd () {
      let result = `cart.add.${this.course.id}`
      if (!isEmpty(this.day) && !isEmpty(this.day.date)) {
        result = result + '.' + this.day.date
      }
      return result
    },
    waitDelete () {
      let result = `cart.delete.${this.course.id}.student.${this.student.id}`
      if (!isEmpty(this.day) && !isEmpty(this.day.date)) {
        result = result + '.' + this.day.date
      }
      return result
    },
    canEnrollWithBalance () {
      return this.registrationBalance < 0 && this.registrationBalance + this.fees <= 0
    },
    registrationBalance () {
      return this.registration.balance
    },
    fees () {
      return this.course.fee + this.course.materialFee
    }
  },
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    doEnrollWithBalance () {
      this.$wait.start(this.waitAdd)
      this.enrollCourseWithBalance(this.student)
        .then(data => {
          console.log('didEnrollWithBalance.data', data)
          this.$wait.end(this.waitAdd)
        })
        .catch((error) => {
          console.error('didEnrollWithBalance.data', error)
          this.$wait.end(this.waitAdd)
          this.$notify({
            group: 'enrollment',
            type: 'error',
            title: this.student.name + ' problem',
            text:
                  this.student.name +
                  ' problem adding student to cart for day.',
            duration: 2000,
            speed: 1000
          })
        })
    },
    addCourseToCart () {
      this.disabled = true
      if (isNull(this.course.session_id)) {
        if (!isNull(this.course.site_calendar_id)) {
          this.$wait.start(this.waitAdd)
          this.addDayToCart(
            this.student,
            this.day.date,
            this.course,
            null,
            null
          )
            .then(data => {
              if (!isEmpty(data.students)) {
                window.cfEventBus.$emit('update-cart', this.program)
                const students = data.students
                for (const studentId in students) {
                  const student = students[studentId]
                  var message = ''
                  if (!isEmpty(student.courses)) {
                    const courses = student.courses
                    var courseNames = []
                    for (const courseId in courses) {
                      const course = courses[courseId]
                      const courseDay = moment(course.day).format('M/D')
                      courseNames.push(course.name + ' ' + courseDay)
                    }
                    message = courseNames.join(',')
                  }
                  this.$notify({
                    group: 'enrollment',
                    type: 'success',
                    title: student.first + ' added to cart',
                    text: message,
                    duration: 2000,
                    speed: 1000
                  })
                }
              }
              this.$wait.end(this.waitAdd)
              console.log('did-add-to-cart', this.student, this.course)
              this.$emit('did-add-to-cart', this.student, this.course)
            })
            .catch(() => {
              this.$wait.end(this.waitAdd)
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.student.name + ' problem',
                text:
                      this.student.name +
                      ' problem adding student to cart.',
                duration: 2000,
                speed: 1000
              })
            })
        }
      } else {
        this.$wait.start(this.waitAdd)
        this.addToCart(this.student)
          .then(() => {
            this.$wait.end(this.waitAdd)
            console.log('did-add-to-cart', this.student, this.course)
            this.$emit('did-add-to-cart', this.student, this.course)
          })
          .catch(() => {
            this.$wait.end(this.waitAdd)
            this.disabled = false
          })
      }
    },
    removeFromCart () {
      if (!isNull(this.course.site_calendar_id)) {
        this.$wait.start(this.waitDelete)
        this.removeDayFromCart(
          this.student,
          {
            id: this.cart.course_id,
            name: this.cart.course
          },
          this.calendar,
          this.registration
        )
          .then(data => {
            if (!isEmpty(data.students)) {
              window.cfEventBus.$emit('update-cart', this.program)
              const students = data.students
              for (const studentId in students) {
                const student = students[studentId]
                var message = ''
                if (!isEmpty(student.courses)) {
                  const courses = student.courses
                  var courseNames = []
                  for (const courseId in courses) {
                    const course = courses[courseId]
                    const courseDay = moment(course.day).format('M/D')
                    courseNames.push(course.name + ' ' + courseDay)
                  }
                  message = courseNames.join(',')
                }
                this.$notify({
                  group: 'enrollment',
                  type: 'success',
                  title: student.first + ' added to cart',
                  text: message,
                  duration: 2000,
                  speed: 1000
                })
              }
            }
            this.$wait.end(this.waitDelete)
          })
          .catch(() => {
            this.$wait.end(this.waitDelete)
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.student.name + ' problem',
              text:
                    this.student.name +
                    ' problem adding student to cart for day.',
              duration: 2000,
              speed: 1000
            })
          })
      } else {
        this.$wait.start(this.waitDelete)
        this.removeCourseFromCart(this.student, this.course, this.session, this.program.slug, this.registration)
          .then(() => {
            this.$wait.end(this.waitDelete)
          })
          .catch(() => {
            this.$wait.end(this.waitDelete)
            this.disabled = false
          })
      }
    },
    dropEnrolled () {
      this.$wait.start(this.waitDelete)
      var enrolled = this.enrolled_course
      this.disabled = true
      this.drop(enrolled, this.course, this.student)
        .then(function () {
          this.$wait.end(this.waitDelete)
        })
        .catch(function () {
          this.$wait.end(this.waitDelete)
        })
    }
  }

}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}

.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}
</style>
