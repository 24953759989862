<template>
  <cs-button
      v-if="inCart"
      :key="student.id"
      @handle-click="removeFromCart()"
      type="warning"
      icon-left="drop-cart"
      :loading="$wait.is(waitDelete)"
  >
    Remove {{ student.first }}
  </cs-button>
    <cs-button
        v-else-if="item.public && !blockEnrollmentWithUnpaid"
        @handle-click="addCourseToCart()"
        type="success"
        icon-left="add-cart"
        :loading="$wait.is(waitAdd)"
    >
      <slot>
        Put in cart for {{ student.first }}
      </slot>

    </cs-button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import { isEmpty } from 'lodash/core'

// given a item this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'ItemCartButton',
  mixins: [courseMethods],
  props: {
    student: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    bulk: {
      type: Boolean,
      required: false,
      default: false
    },
    enrolled: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      studentSelections: []
    }
  },
  created () {
    this.program_slug = this.program.slug
  },
  mounted () {},
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      store: 'selectedStore',
      students: 'students',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'gteManager',
      blockEnrollmentWithUnpaid: 'blockEnrollmentWithUnpaid'
    }),
    course () {
      return this.item
    },
    session () {
      return this.store
    },
    isStudentEnrolledEmit () {
      if (this.isStudentEnrolled) {
        if (!this.item.public) {
          this.$emit('privateEnrolled')
        }
        return true
      } else {
        return false
      }
    },
    isDropped () {
      const enrolled = this.enrolled_course
      if (!isEmpty(enrolled) && enrolled.dropped) {
        return true
      } else {
        return false
      }
    },
    waitAdd () {
      let result = `cart.add.${this.item.id}.student.${this.student.id}`
      if (!isEmpty(this.day) && !isEmpty(this.day.date)) {
        result = result + '.' + this.day.date
      }
      return result
    },
    waitDelete () {
      let result = `cart.delete.${this.item.id}.student.${this.student.id}`
      if (!isEmpty(this.day) && !isEmpty(this.day.date)) {
        result = result + '.' + this.day.date
      }
      return result
    },
    canEnrollWithBalance () {
      return this.registrationBalance < 0 && this.registrationBalance + this.fees <= 0
    },
    registrationBalance () {
      return this.registration.balance
    },
    fees () {
      return this.item.fee + this.item.materialFee
    }
  },
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    doEnrollWithBalance () {
      this.$wait.start(this.waitAdd)
      this.enrollCourseWithBalance(this.student)
        .then(data => {
          console.log('didEnrollWithBalance.data', data)
          this.$wait.end(this.waitAdd)
        })
        .catch((error) => {
          console.error('didEnrollWithBalance.data', error)
          this.$wait.end(this.waitAdd)
          this.$notify({
            group: 'enrollment',
            type: 'error',
            title: this.student.name + ' problem',
            text:
                  this.student.name +
                  ' problem adding student to cart for day.',
            duration: 2000,
            speed: 1000
          })
        })
    },
    addCourseToCart () {
      this.disabled = true

      this.addToCart(this.student)
        .then(() => {
          this.$wait.end(this.waitAdd)
          console.log('did-add-to-cart', this.student, this.item)
          this.$emit('did-add-to-cart', this.student, this.item)
        })
        .catch(() => {
          this.$wait.end(this.waitAdd)
          this.disabled = false
        })
    },
    removeFromCart () {
      this.removeCourseFromCart(this.student, this.item, this.session, this.program.slug, this.registration)
        .then(() => {
        })
        .catch(() => {
          this.disabled = false
        })
    },
    dropEnrolled () {
      this.$wait.start(this.waitDelete)
      var enrolled = this.enrolled_course
      this.disabled = true
      this.drop(enrolled, this.item, this.student)
        .then(function () {
          this.$wait.end(this.waitDelete)
        })
        .catch(function () {
          this.$wait.end(this.waitDelete)
        })
    }
  }

}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}

.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}
</style>
