var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.sessionsStatus == 'loading')?_c('div',[_c('div',{key:"loading",staticClass:"alert alert-info"},[_c('h2',[_c('cs-loading',{attrs:{"theme":"info"}},[_vm._v("\n            Loading "+_vm._s(_vm.titleCase(_vm.plural(_vm.program.course_session_label, 2)))+"\n          ")])],1)])]):_c('div',{key:"loaded",attrs:{"id":"sessions"}},[(_vm.sessions.length > 0)?_c('div',{key:"sessions",staticClass:"card w-100"},[_c('div',{staticClass:"card-header"},[_c('h3',[_vm._v(_vm._s(_vm.titleCase(_vm.plural(_vm.program.course_session_label, _vm.sessions.length)))+" for "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.program.name)}})])]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('nav',{staticClass:"nav nav-pills flex-column flex-sm-row"},_vm._l((_vm.sessions),function(session){return _c('button',{key:session.id,staticClass:"flex-sm-fill text-sm-center nav-link",class:{
                'bg-success text-light font-weight-bold':
                  (_vm.selectedSession == null ||
                    session.id === _vm.selectedSession.id) &&
                  (session.registrationOpen || session.earlyRegistrationOpen),
                'bg-success-light':
                  (session.registrationOpen ||
                    session.earlyRegistrationOpen) &&
                  !(
                    _vm.selectedSession == null ||
                    session.id === _vm.selectedSession.id
                  ),
                'bg-info text-light font-weight-bold':
                  !(
                    session.registrationOpen || session.earlyRegistrationOpen
                  ) &&
                  (_vm.selectedSession == null ||
                    session.id === _vm.selectedSession.id),
                'bg-info-light':
                  !(
                    session.registrationOpen || session.earlyRegistrationOpen
                  ) &&
                  !(
                    _vm.selectedSession == null ||
                    session.id === _vm.selectedSession.id
                  )
              },on:{"click":function($event){$event.preventDefault();return _vm.selectSession(session)}}},[(_vm.$wait.is('loading-session.' + session.id))?_c('cs-loading',{attrs:{"sm":true,"theme":"light"}}):_vm._e(),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(session.name)}}),_vm._v(" "),(_vm.showOpenBadge(session))?_c('span',{staticClass:"ml-2 badge badge-primary"},[_vm._v("OPEN")]):_vm._e(),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"text-dark"},[_vm._v("\n                "+_vm._s(_vm.dateRange(session.startDate, session.endDate,'-' , 'MMM do'))+"\n              ")])],1)}),0),_vm._v(" "),(_vm.selectedSession !== null && _vm.selectedSession.id !== 0)?_c('app-session',{attrs:{"id":"session","loggedIn":_vm.loggedIn}}):_vm._e()],1)]):_c('div',{staticClass:"card w-100 border-warning"},[_c('div',{staticClass:"card-header bg-warning"},[_c('h4',[_vm._v("No sessions available for "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.program.name)}})])]),_vm._v(" "),_c('div',{staticClass:"card-body bg-warning-light"},[_c('p',{staticClass:"lead"},[_vm._v("\n            Please check with the "+_vm._s(_vm.program.admin_name)+" for more\n            information\n            "),_c('a',{staticClass:"btn btn-info",attrs:{"href":'mailto:' + _vm.program.admin_email}},[_c('font-awesome-icon',{attrs:{"icon":"envelope"}}),_vm._v("\n              "+_vm._s(_vm.program.admin_name))],1),_vm._v("\n            "+_vm._s(_vm.phone(_vm.program.phone))+" for more information\n          ")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }