<template>
  <div class="py-2">
    <div class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center"
        :class="{
          'bg-danger': spaceDanger,
          'bg-warning': spaceWarning
        }"
      >
        <strong><span v-html="group.name"></span></strong>
        <span
          >{{ timeRange(group.start_time,group.end_time)
          }}</span
        >
        <button @click.prevent="showBrief = !showBrief" class="btn btn-info">
          <font-awesome-icon icon="info" />
        </button>
      </div>
      <div class="list-group list-group-flush">
        <div v-if="hasClosed" class="list-group-item list-group-item-danger">
          This group has at least one closed class, you may be able to enroll in
          the other classes as a la cart classes.
        </div>
        <div
          v-else-if="minSpace === 0"
          class="list-group-item list-group-item-danger"
        >
          This group has at least one class that is full, you can enroll in the
          other classes as a la cart classes.
        </div>
        <div
          v-else-if="spaceDanger"
          class="list-group-item list-group-item-danger"
        >
          This group has at least one class that is almost full, remember that
          the classes in the group are not enrolled until you have paid for
          them.
        </div>
        <div
          v-if="spaceWarning"
          class="list-group-item list-group-item-warning"
        >
          This group has at least one class approaching capacity, remember that
          that the classes in the group are not enrolled until you have paid for
          them.
        </div>
        <span
          v-else-if="blockEnrollmentWithUnpaid"
          class="list-group-item list-group-item-danger"
        >
          New signup closed due to unpaid registrations
        </span>
        <div
          class="list-group-item d-flex justify-content-between align-items-center"
        >
          <span>{{ fee | currency }}</span>
          <span v-if="materialFee > 0"
            >Materials:{{ materialFee | currency }}</span
          >
          <span v-if="discount > 0" class="text-success"
            >{{ discount | currency }} Savings</span
          >
          <span v-else>No discount</span>
        </div>
        <div
          v-if="
            registrationOpen &&
              !hasClosed &&
              minSpace > 0 &&
              !blockEnrollmentWithUnpaid
          "
          class="list-group-item d-flex justify-content-between align-items-center"
        >
          <span
            ><app-enroll-group-buttons
              :group="group"
              :member="member"
            ></app-enroll-group-buttons
          ></span>
        </div>

        <div v-if="!showBrief" class="list-group-item list-group-item-light">
          <ul>
            <li
              v-for="course in group.courses"
              :key="course.id"
              class="list-unstyled"
              :class="{
                'text-danger': courseSpaceDanger(course),
                'text-warning': courseSpaceWarning(course)
              }"
            >
              <small
                ><span v-html="course.name"></span> ({{
                  course.grade_list
                }})</small
              >
            </li>
          </ul>
        </div>
      </div>

      <table v-if="showBrief" class="table table-condensed table-striped">
        <thead>
          <th>Times</th>
          <th>Name</th>
          <th v-if="registrationOpen && students.length > 0" class="action">
            Action
          </th>
        </thead>
        <tbody
          v-for="course in group.courses"
          :key="'group-' + group.id + '-course-' + course.id"
        >
          <tr
            :class="{
              'table-danger': courseSpaceDanger(course),
              'table-warning': courseSpaceWarning(course)
            }"
          >
            <td>
              {{ timeRange(course.startTime,course.endTime)
              }}
            </td>
            <td>
              <span v-html="course.name"></span>
              <small>&nbsp;{{ course.grade_list }}</small>
            </td>
            <td
              v-if="
                courseOpen(course) &&
                  students.length > 0 &&
                  !courseSpaceFull(course)
              "
            >
              <app-enroll-buttons
                :course="course"
                :students="students"
              ></app-enroll-buttons>
            </td>
            <td v-else-if="courseSpaceFull(course)" class="table-danger">
              <span class="text-danger">FULL</span>
            </td>
          </tr>
          <transition key="course.id">
            <tr v-if="showBrief">
              <td colspan="3"><span v-html="course.brief"></span></td>
            </tr>
          </transition>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SIZE_WARNING, SIZE_ALERT } from '@/constants/application'
import { courseMethods } from '@/components/mixins/course'
import EnrollButtons from '@/components/shared/EnrollButtons.vue'
import EnrollGroupButtons from '@/components/calendars_sessions/sessions/courses/EnrollGroupButtons.vue'
import { timeRange } from '@/utilities/dateFormatters'

export default {
  name: 'CourseBlock',
  components: {
    appEnrollButtons: EnrollButtons,
    appEnrollGroupButtons: EnrollGroupButtons
  },
  mixins: [courseMethods],
  props: ['group'],
  data () {
    return {
      showBrief: false,
      loading: true
    }
  },
  mounted: function () {},
  computed: {
    ...mapGetters({
      registration: 'registration',
      member: 'registrationMember',
      students: 'students',
      session: 'selectedSession',
      enrolledCourses: 'enrolledCourses',
      loggedIn: 'loggedIn',
      blockEnrollmentWithUnpaid: 'blockEnrollmentWithUnpaid'
    }),
    spaceWarning: function () {
      return this.minSpace > SIZE_ALERT && this.minSpace <= SIZE_WARNING
    },
    spaceDanger: function () {
      return this.minSpace <= SIZE_ALERT
    },
    registrationOpen: function () {
      if (this.students != null && this.students.length >= 1) {
        if (
          typeof this.registration !== 'undefined' &&
          this.registration != null
        ) {
          if (
            this.session.registrationOpen ||
            (this.session.earlyRegistrationOpen && this.memberGte('staff'))
          ) {
            return true
          }
        }
      }
      return false
    },
    discount: function () {
      const amount = this.group.discount
      let percent = this.group.discount_percent
      const totalCost = this.group.courses.reduce(function (a, b) {
        return b.fee == null ? a : a + b.fee
      }, 0)
      percent = totalCost * (percent / 100)
      if (amount > percent) {
        return amount
      } else {
        return percent
      }
    },
    materialFee: function () {
      return this.group.courses.reduce(function (a, b) {
        return b.materialFee == null ? a : a + b.materialFee
      }, 0)
    },
    fee: function () {
      return this.group.courses.reduce(function (a, b) {
        return b.fee == null ? a : a + b.fee
      }, 0)
    },
    minSpace: function () {
      const min = this.group.courses.reduce(function (prev, current) {
        return prev.space < current.space ? prev : current
      }) // returns object
      return min.space
    },
    countDanger: function () {
      const filtered = this.group.courses.filter(function (course) {
        return course.space <= SIZE_ALERT
      })
      return filtered.length
    },
    countWarning: function () {
      const filtered = this.group.courses.filter(function (course) {
        return course.space > SIZE_ALERT && course.space <= SIZE_WARNING
      })
      return filtered.length
    },
    countFull: function () {
      const filtered = this.group.courses.filter(function (course) {
        return course.space <= 0
      })
      return filtered.length
    },
    hasClosed: function () {
      const filtered = this.group.courses.filter(function (course) {
        return course.state === 'CLOSED'
      })
      return filtered.length
    }
  },
  methods: {
    timeRange,
    courseOpen: function (course) {
      if (this.registrationOpen) {
        return true
      } else if (course.state === 'OPEN') {
        return true
      }
      return false
    },
    courseSpaceWarning: function (course) {
      return course.space > SIZE_ALERT && course.space <= SIZE_WARNING
    },
    courseSpaceDanger: function (course) {
      return course.space <= SIZE_ALERT && course.space > 0
    },
    courseSpaceFull: function (course) {
      return course.space <= 0
    }
  }
}
</script>

<style scoped>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s;
}
</style>
