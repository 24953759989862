<template>
  <div class="card"
       :class="isEmpty(this.size)?
      'bg-warning-light border-warning':'bg-success-light border-success'">
    <h6 class="card-header"
        :class="isEmpty(this.size)?
      'bg-warning':'bg-success text-light'">
      <span v-html="student.first"/> T Shirt Size
    </h6>
    <div v-if="hasSession || hasCourse" class="card-body">
      <p v-if="hasCourse" class="card-text"><span v-html="course.name"/> needs a t-shirt size</p>
      <template v-if="hasSession">
        <p class="card-text"><span v-html="session.name"/> needs a t-shirt size.<span v-if="session.t_shirt_fee > 0" class="text-danger"> Selecting a size will add {{ currency(session.t_shirt_fee)}} to the registration.</span><span v-if="session.free_t_shirt_early" class="text-success"> The t-shirt is included with <strong>Early Bird Registration</strong> with no fee.</span> </p>
      </template>
    </div>
    <div class="card-footer">
      <multiselect
          :value="size"
          :disabled="$wait.is('tshirt.set.' + this.student.id)"
          deselect-label="Press enter to remove"
          track-by="id"
          label="size"
          placeholder="Select t-shirt size"
          :options="sizes"
          :searchable="false"
          :allow-empty="true"
          @input="selectSize"
      >
      </multiselect>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import { setTShirtSize, tShirtSizes } from '@/api/tShirtAPI'
import isNil from 'lodash/isNil'
import { currency } from '@/utilities/stringFormatters'

export default {
  name: 'TShirtPicker',
  props: {
    student: {
      type: Object,
      required: true
    },
    course: {
      type: Object,
      required: false,
      default: () => {}
    },
    session: {
      type: Object,
      required: false,
      default: () => {}
    },
    initial_size: {
      type: String,
      required: true
    },
    registration_id: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      size: null,
      disabled: false,
      sizes: tShirtSizes
    }
  },
  mounted () {
    if (!isEmpty(this.initial_size)) {
      this.size = this.sizes.find(size => {
        return size.id === this.initial_size
      })
    }
  },
  computed: {
    hasCourse () {
      return !isEmpty(this.course)
    },
    hasSession () {
      return !isEmpty(this.session)
    }
  },
  methods: {
    isEmpty,
    currency,
    selectSize: function (size) {
      this.$wait.start('tshirt.set.' + this.student.id)
      let sizeId = null
      if (!isNil(size) && !isNil(size.id)) {
        sizeId = size.id
      }
      setTShirtSize(this.student.id, this.registration_id, sizeId)
        .then(result => {
          this.$wait.end('tshirt.set.' + this.student.id)
          this.size = size
          let textForMessage = this.student.name + ' t-shirt size removed'
          if (size !== null && typeof size === 'undefined') {
            textForMessage = this.student.name + ' t shirt size now ' + this.size.size
          }
          this.$notify({
            group: 'enrollment',
            type: 'success',
            title: 'Set Size for ' + this.student.first,
            text: textForMessage,
            duration: 2000,
            speed: 1000
          })
          this.$emit('selected', size, this.student)
        })
        .catch(e => {
          this.$wait.end('tshirt.set.' + this.student.id)
          this.$notify({
            group: 'enrollment',
            type: 'error',
            title: 'Set Size',
            text: e.message,
            duration: 2000,
            speed: 1000
          })
        })
    }
  }
}
</script>

<style scoped>

</style>
