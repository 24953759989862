<template>
  <div>
    <div v-if="purchasedNames.length > 0" class="list-group-item">
      <h5><strong>Purchased for</strong> {{ toList(purchasedNames) }}</h5>
        <p v-if="isManager" class="small text-muted">You can delete order from registration.</p>
    </div>
    <item-buy-buttons v-if="showPurchase || isManager"
                           :item="item"
                           :students="studentsWhoHaveNotPurchased"
                           class="list-group-item-primary"
    >
      <h6 class="text-center m-1" v-if="!isManager">Due to <strong>credit</strong>({{ currency(registrationBalance)}}) you can purchase directly without adding to the cart.</h6>
    </item-buy-buttons>
    <item-cart-buttons v-else-if="showCart"
                           :item="item"
                           :students="studentsWhoHaveNotPurchased"
                           class="list-group-item-success"
    >
      <h6 class="text-center m-1">Add for {{ toList(namesWhoHaveNotPurchased) }} to shopping cart</h6>
    </item-cart-buttons>
  </div>

</template>

<script>
import ItemCartButtons from '@/components/calendars_sessions/stores/ItemCartButtons.vue'
import ItemBuyButtons from '@/components/calendars_sessions/stores/ItemBuyButtons.vue'
import { courseMethods } from '@/components/mixins/course'
import isEmpty from 'lodash/isEmpty'
import { currency, toList } from '@/utilities/stringFormatters'
import { mapGetters } from 'vuex'

export default {
  name: 'ItemBuy',
  mixins: [courseMethods],
  components: {
    ItemCartButtons,
    ItemBuyButtons
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    students: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      isManager: 'isProgramManager'
    }),
    purchasedNames () {
      return this.purchasedStudents.map(student => {
        return student.first
      })
    },
    purchasedStudents () {
      return this.students.filter(student => {
        const purchased = this.enrolledCourseStudent(
          this.item,
          student
        )
        return !isEmpty(purchased)
      })
    },
    namesWhoHaveNotPurchased () {
      return this.studentsWhoHaveNotPurchased.map(student => {
        return student.first
      })
    },
    studentsWhoHaveNotPurchased () {
      return this.students.filter(student => {
        const enrollment = this.enrolledCourseStudent(
          this.item,
          student
        )
        return isEmpty(enrollment)
      })
    },
    showPurchase () {
      return this.item.space > 0 &&
          this.canPurchaseWithBalance &&
          this.studentsWhoHaveNotPurchased.length > 0
    },
    showCart () {
      return this.item.space > 0 &&
          this.studentsWhoHaveNotPurchased.length > 0
    },
    canPurchaseWithBalance () {
      return this.studentsWhoHaveNotPurchased.length > 0 &&
          this.registrationBalance < 0 &&
          this.registrationBalance + this.fees <= 0
    },
    registrationBalance () {
      return this.registration.amountDue - this.registration.amountPaid
    },
    fees () {
      return this.item.fee + this.item.materialFee
    }
  },
  methods: {
    currency,
    toList
  }
}
</script>

<style scoped>

</style>
