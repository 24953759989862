<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="sessionsStatus == 'loading'">
        <div class="alert alert-info" key="loading">
          <h2>
            <cs-loading theme="info">
              Loading {{ titleCase(plural(program.course_session_label, 2)) }}
            </cs-loading>
          </h2>
        </div>
      </div>
      <div id="sessions" v-else key="loaded">
        <div v-if="sessions.length > 0" key="sessions" class="card w-100">
          <div class="card-header">
            <h3>{{ titleCase(plural(program.course_session_label, sessions.length)) }} for <span v-html="program.name"/></h3>
          </div>
          <div class="card-body">
            <nav class="nav nav-pills flex-column flex-sm-row">
              <button
                  v-for="session in sessions"
                  :key="session.id"
                  :class="{
                  'bg-success text-light font-weight-bold':
                    (selectedSession == null ||
                      session.id === selectedSession.id) &&
                    (session.registrationOpen || session.earlyRegistrationOpen),
                  'bg-success-light':
                    (session.registrationOpen ||
                      session.earlyRegistrationOpen) &&
                    !(
                      selectedSession == null ||
                      session.id === selectedSession.id
                    ),
                  'bg-info text-light font-weight-bold':
                    !(
                      session.registrationOpen || session.earlyRegistrationOpen
                    ) &&
                    (selectedSession == null ||
                      session.id === selectedSession.id),
                  'bg-info-light':
                    !(
                      session.registrationOpen || session.earlyRegistrationOpen
                    ) &&
                    !(
                      selectedSession == null ||
                      session.id === selectedSession.id
                    )
                }"
                  v-on:click.prevent="selectSession(session)"
                  class="flex-sm-fill text-sm-center nav-link"
              >
                <cs-loading v-if="$wait.is('loading-session.' + session.id)" :sm="true" theme="light"></cs-loading>
                <span v-html="session.name"></span>
                <span v-if="showOpenBadge(session)"
                      class="ml-2 badge badge-primary">OPEN</span>
                <br/>
                <span class="text-dark">
                  {{ dateRange(session.startDate, session.endDate,'-' , 'MMM do') }}
                </span>
              </button>
            </nav>
            <app-session
                id="session"
                v-if="selectedSession !== null && selectedSession.id !== 0"
                :loggedIn="loggedIn"
            ></app-session>
          </div>
        </div>
        <div v-else class="card w-100 border-warning">
          <div class="card-header bg-warning">
            <h4>No sessions available for <span v-html="program.name"/></h4>
          </div>
          <div class="card-body bg-warning-light">
            <p class="lead">
              Please check with the {{ program.admin_name }} for more
              information
              <a
                  v-bind:href="'mailto:' + program.admin_email"
                  class="btn btn-info"
              >
                <font-awesome-icon icon="envelope"/>
                {{
                program.admin_name
                }}</a
              >
              {{ phone(program.phone) }} for more information
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Session from '@/components/calendars_sessions/sessions/Session.vue'
import { UPDATE_SESSIONS, UPDATE_SESSION } from '@/constants/mutation-types'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import { dateRange } from '@/utilities/dateFormatters'
import { titleCase, plural, phone } from '@/utilities/stringFormatters'

export default {
  name: 'Sessions',
  props: {
    sessionId: {
      type: [Number, String],
      default: ''
    },
    courseSlug: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      courseId: ''
    }
  },
  components: {
    appSession: Session
  },
  created () {
    this.$wait.start('loading-sessions')
    this.$store.dispatch(UPDATE_SESSIONS, {})
      .then(result => {
        this.initSessionFromSearchParams()
        this.$wait.end('loading-sessions')
        console.log('Sessions.created.sessionId', this.sessionId)
        if (typeof this.sessionId !== 'undefined' && this.sessionId !== '' && this.sessionId !== null) {
          const sessionId = this.sessionId
          const foundSession = this.sessions.find(session => {
            console.log('Sessions.created.sessions', sessionId, session)
            return sessionId.toString() === session.id.toString()
          })
          console.log('Sessions.created foundSession', sessionId.toString(), foundSession)
          if (!isEmpty(foundSession)) {
            this.selectSession(foundSession)
          }
        }
        console.log('result', result)
      })
      .catch(e => {
        console.error('error loading sessions', e)
        this.$wait.end('loading-sessions')
      })
  },
  computed: {
    ...mapGetters({
      registration: 'registration',
      unfilteredSessions: 'sessions',
      sessionsStatus: 'sessionsStatus',
      selectedSession: 'selectedSession',
      students: 'students',
      program: 'program',
      loggedIn: 'loggedIn',
      member: 'registrationMember'
    }),
    sessions () {
      return this.unfilteredSessions.filter(session => {
        return !session.not_courses
      })
    }
  },
  watch: {
    sessions () {
      console.log('Sessions.watch.sessions', this.sessions, this.sessions.length)
      if (this.sessions.length === 1) {
        this.selectSession(this.sessions[0])
      }
    }
  },
  methods: {
    ...mapMutations([UPDATE_SESSIONS, 'sessionsLoadingStatus']),
    ...mapActions([UPDATE_SESSION]),
    dateRange,
    titleCase,
    plural,
    phone,
    scrollToSelectedCourse (slug) {
      const found = this.selectedSession.courses.some(course => {
        console.log('Sessions.scrollToSelectedCourse', slug, course.slug === slug, course)
        return course.slug === slug
      })
      if (found) {
        console.log('Sessions.scrollToSelectedCourse will scrollTo', slug)
      }
      this.$emit('update:course-slug', '')
      const vm = this
      this.$nextTick(() => {
        vm.$scrollTo('#'.slug)
      })
    },
    selectSession: function (session) {
      this.$wait.start('loading-session.' + session.id)
      console.log('Sessions.selectSession', session)
      console.trace('Sessions.selectSession')
      this.$emit('update:session-id', session.id)
      this.updateSession(session)
        .then(() => {
          this.$wait.end('loading-session.' + session.id)
          if (!isEmpty(this.courseSlug)) {
            this.scrollToSelectedCourse(this.courseSlug)
          }
        })
        .catch(() => {
          this.$wait.end('loading-session.' + session.id)
        })
      const vm = this
      this.$nextTick(function () {
        vm.$scrollTo('#session')
      })
    },
    initSessionFromSearchParams () {
      const url = new URL(window.location.href)
      const searchParams = url.searchParams
      if (searchParams.has('session_id')) {
        const session = this.sessions.find(session => {
          return parseInt(session.id) === parseInt(searchParams.get('session_id'))
        })
        if (!isEmpty(session)) {
          this.selectSession(session)
        }
      }
    },
    showOpenBadge (session) {
      if (session.registrationOpen) {
        return true
      }
      const earlyRoles = ['staff', 'program', 'admin']
      return !!(this.loggedIn && !isEmpty(this.member) && !isEmpty(this.member.role) &&
          earlyRoles.includes(this.member.role) &&
          session.earlyRegistrationOpen)
    }
  }

}
</script>

<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
</style>
