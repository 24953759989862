<template>
  <div>
    <template v-if="course.waitlist === 'none'">
      <slot name="no-waitlist">
        <strong>
          Class is full, contact {{ program.admin_name }} {{ program.phone }}
          <a :href="'mailto:' + program.admin_email">{{ program.admin_email }}</a>
        </strong>
      </slot>
    </template>
    <template v-else-if="course.waitlist === 'notify'">
      Add To Waitlist Notifications
      <div class="btn-group" v-if="students">
        <template v-for="student in students">
          <cs-button
            v-if="inWaitlist(student)"
            :key="student.id"
            @handle-click="removeFromWaitlist(waitList(student), $event)"
            :loading="$wait.is('waitlist.remove.' + course.id + '.' + student.id)"
            icon-left="waitlist"
            type="info"
          >
            Remove {{ student.first }} from waitlist
          </cs-button>
          <cs-button
            v-else
            :key="student.id"
            :title="student.name + ' will be added to notification list'"
            @handle-click="addToWaitlist(student, $event)"
            :loading="$wait.is('waitlist.add.' + course.id + '.' + student.id)"
            icon-left="waitlist"
            type="warning"
          >
            Add {{ student.first }} to notification
          </cs-button>
        </template>
      </div>
    </template>
    <template v-else>
      <slot name="default">
        <strong class="mx-2 my-1">Add to Waitlist!</strong>
      </slot>
      <div class="d-flex justify-content-around">
        <template v-for="student in studentsWhoCanWaitlist">
          <cs-button
            v-if="inWaitlist(student)"
            :key="student.id"
            @handle-click="removeFromWaitlist(waitList(student), $event)"
            :loading="$wait.is('waitlist.remove.' + course.id + '.' + student.id)"
            icon-left="waitlist"
            type="info"
          >
            Remove {{ student.first }}
          </cs-button>
          <cs-button
            v-else
            :key="student.id"
            :title="student.name + ' will be added to waitlist'"
            @handle-click="addToWaitlist(student, $event)"
            :loading="$wait.is('waitlist.add.' + course.id + '.' + student.id)"
           icon-left="waitlist"
            type="warning"
          >
            Add {{ student.first }}
          </cs-button>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import isEmpty from 'lodash/isEmpty'
import CsButton from '@/components/controls/CsButton'
export default {
  name: 'CourseWaitlistButtons',
  mixins: [courseMethods],
  components: {
    CsButton
  },
  props: {
    course: {
      type: Object,
      required: true
    },
    students: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      addedToWaitlist: {}
    }
  },
  created () {
    this.program_slug = this.program.slug
  },
  mounted () {},
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    inWaitlist (student) {
      if (!isEmpty(this.waitlists)) {
        const foundInWaitlist = this.waitlists.find(entry => {
          return entry.student_id.toString() === student.id.toString() && this.course.id === entry.course_id && this.member.id === entry.member_id
        })
        if (!isEmpty(foundInWaitlist) || (this.addedToWaitlist[student.id] !== undefined && this.addedToWaitlist[student.id])) {
          if (this.addedToWaitlist[student.id] !== undefined && !this.addedToWaitlist[student.id]) {
            return false
          }
          return true
        }
      }
      return false
    },
    waitList (student) {
      if (!this.inWaitlist(student)) {
        return null
      }
      return this.waitlists.find(entry => {
        return entry.student_id.toString() === student.id.toString() && this.course.id === entry.course_id && this.member.id === entry.member_id
      })
    },
    isStudentEnrolled (student) {
      const course = this.course
      if (
        typeof course.students !== 'undefined' &&
        course.students.length > 0
      ) {
        const inCourse = course.students.find(function (checkStudent) {
          return checkStudent.id === student.id
        })
        if (typeof inCourse !== 'undefined') {
          return true
        }
      }
      return false
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      enrolled_courses: 'enrolledCourses',
      enrolledCourses: 'enrolledCourses',
      member: 'registrationMember',
      waitlists: 'waitlists',
      tWait: 'waitlists'
    }),
    studentsNotInCart () {
      if (isEmpty(this.studentsWhoCanEnroll)) {
        return []
      }
      return this.studentsWhoCanEnroll.filter(student => {
        return !this.cartForStudentInCourse(student, this.course)
      })
    },
    studentsInCart () {
      if (isEmpty(this.studentsWhoCanEnroll)) {
        return []
      }
      return this.studentsWhoCanEnroll.filter(student => {
        return this.cartForStudentInCourse(student, this.course)
      })
    },
    studentsWhoCanWaitlist () {
      function isInGrade (value) {
        if (typeof value.grade === 'undefined') {
          return false
        }
        return this.includes(value.grade)
      }

      if (typeof this.studentsNotInCart !== 'undefined' && this.studentsNotInCart.length > 0) {
        let result = this.studentsNotInCart.filter(isInGrade, this.course.grade_keys)
        if (result.length === 0) {
          // try with values
          result = this.studentsNotInCart.filter(isInGrade, this.course.grades)
        }
        return result
      } else {
        return []
      }
    },
    studentsWhoCanEnroll: function () {
      function isInGrade (value) {
        if (typeof value.grade === 'undefined') {
          return false
        }
        return this.includes(value.grade)
      }

      if (typeof this.students !== 'undefined') {
        let result = this.students.filter(isInGrade, this.course.grade_keys)
        if (result.length === 0) {
          // try with values
          result = this.students.filter(isInGrade, this.course.grades)
        }
        return result
      } else {
        return []
      }
    },
    enrolledStudents: function () {
      const vm = this
      let result = this.students.filter(function (student) {
        return vm.isStudentEnrolled(student)
      })
      result = result.map(function (student) {
        return student.first
      })
      return result
    }
  }
}
</script>

<style scoped></style>
