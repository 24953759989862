<template>
  <div
      :id="'session-' + session.id"
      class="card w-100 px-0 mx-0"
      :class="{
      'border-success': session.registrationOpen,
      'border-warning': !session.registrationOpen
    }"
  >
    <div
        class="card-header"
        :class="{
        'bg-success-light': session.registrationOpen,
        'bg-warning-light': !session.registrationOpen
      }"
    >
      <h4>
        <span v-html="session.name"></span>
        <small class="float-right"
        >{{ dateRange(session.startDate, session.endDate, '-', 'MMM do yy') }}
        </small>
      </h4>
    </div>
    <div class="card-body">
      <h5 v-if="session.registrationOpen" class="card-title">
        Registration Closes:
        {{ dateTimeString(session.endRegistration) }}
      </h5>
      <h5 v-else class="card-title">
        Registration Dates:
        {{ dateTimeRange(session.beginRegistration, session.endRegistration) }}
      </h5>

      <p class="lead">
        Students in grades {{ session.grade_list }} can enroll in classes in
        <span v-html="session.name"/>
      </p>
      <p class="lead">Available to students from {{ session.school_names }}</p>

      <div v-if="hasSessionLateFee"
           class="alert"
           :class="hasActiveSessionLateFee?'alert-warning':'alert-info'">
        Session late fee of <span class="currency">{{ currency(session.session_late_fee) }}</span> is applied when
        there is one or more classes enrolled after {{ dateString(session.session_late_fee_date) }}.
        <span v-if="session.ssession_one_late_fee">
          There will be one session late fee assessed per session.
        </span>
        <span v-else>
          There will be one session late fee assessed per student that has at least one class enrolled late.
        </span>
      </div>

      <div class="row" v-if="hasEarlyDiscount">
        <div class="col">
          Early bird discount
          <strong>
            <span v-if="session.early_discount_amount > 0 && session.early_discount_percent > 0"
                  class="currency">
              greater of {{ currency(session.early_discount_amount) }} or
              {{ session.early_discount_percent }} %
            </span>
            <span v-else-if="session.early_discount_amount > 0"
                  class="currency">
              {{ currency(session.early_discount_amount) }}
            </span>
            <span v-else class="currency"
            >{{ session.early_discount_percent }} %</span
            ></strong>
          expires
          <strong>{{ dateString(session.early_discount_expires) }}</strong
          >&nbsp;
        </div>
      </div>
    </div>

    <div class="list-group list-group-flush">
      <div
          v-if="$wait.is('loading-session.' + session.id)"
          class="list-group-item list-group-item-info"
      >
        <h5><cs-loading theme="info">Loading courses...</cs-loading></h5>
      </div>
      <div v-if="this.hasCourses > 0" class="list-group-item">
        <nav class="nav nav-pills flex-column flex-sm-row">
          <button
              v-if="this.hasGroups > 0"
              v-scroll-to="'#course-blocks'"
              class="flex-sm-fill text-sm-center nav-link bg-info-light"
          >
            Course Blocks
          </button>
          <button
              v-scroll-to="'#a-la-cart'"
              class="flex-sm-fill text-sm-center nav-link bg-info-light"
          >
            A La Cart
          </button>
          <button
              v-if="program.group_courses_by_start_time"
              v-scroll-to="'#hourly-courses'"
              class="flex-sm-fill text-sm-center nav-link bg-info-light"
          >
            Hourly Courses
          </button>
          <button
              v-if="program.group_courses_by_start_time"
              v-scroll-to="'#long-courses'"
              class="flex-sm-fill text-sm-center nav-link bg-info-light"
          >
            Specialty (long) courses
          </button>
          <button
              v-if="notCourses.length > 0"
              v-scroll-to="'#not-courses'"
              class="flex-sm-fill text-sm-center nav-link bg-info-light"
          >
            Non-course Items
          </button>
        </nav>
      </div>
      <transition-group>
        <div v-if="this.hasGroups > 0" class="list-group-item" key="groups">
          <h5 id="course-blocks">Blocks of courses</h5>
          <hourly-groups :hourly="groupsByStartTime"></hourly-groups>
        </div>
        <div id="a-la-cart" v-if="this.hasCourses" class="list-group-item" key="a-la-cart">
          <template v-if="program.group_courses_by_start_time">
            <hourly-courses
                :hourly="coursesByStartTime"
            ></hourly-courses>
            <long-courses
                :courses="longCourses"
                :longCourse="longCourse"
            ></long-courses>
          </template>
          <template v-else>
            <div class="row">
              <div
                  v-for="course in openCourses"
                  class="col-xl-4 col-lg-6"
                  :key="course.id"
              >
                <cs-course :course="course"></cs-course>
              </div>
            </div>
          </template>
        </div>
        <div v-if="closedCourses.length > 0" class="list-group-item list-group-item-notice" key="closed">
          <h5>Closed Courses</h5>
          <div class="row">
            <div
                v-for="course in closedCourses"
                class="col-xl-4 col-lg-6"
                :key="course.id"
            >
              <cs-course :course="course"></cs-course>
            </div>
          </div>
        </div>
        <div id="not-courses" v-if="this.notCourses.length > 0" class="list-group-item" key="not-courses">
          <h5>Non-Course Items</h5>
          <div class="row row-cols-md-3 row-cols-lg-4">
            <not-course v-for="item in this.notCourses"
                        :item="item"
                 class="col"
                 :key="`not_course_${item.id}`">
              {{ item.name }}
            </not-course>
          </div>

        </div>
      </transition-group>
    </div>
    <div class="card-footer" v-if="status !== 'loading'">
      <nav class="nav nav-pills flex-column flex-sm-row">
        <a
            class="flex-sm-fill text-sm-center nav-link disabled"
            href="#"
            tabindex="-1"
            aria-disabled="true"
        >Scroll up to</a
        >
        <button
            class="flex-sm-fill text-sm-center nav-link bg-info-light"
            v-scroll-to="'#registration'"
        >
          Registration
        </button>
        <button
            class="flex-sm-fill text-sm-center nav-link bg-info-light"
            v-scroll-to="'#sessions'"
        >
          Sessions
        </button>
        <button
            class="flex-sm-fill text-sm-center nav-link bg-info-light"
            v-scroll-to="'#session-' + session.id"
        >
          Top <span v-html="session.name"></span>
        </button>
      </nav>
    </div>
  </div>
</template>

<script>
import HourlyGroups from '@/components/calendars_sessions/sessions/courses/HourlyGroups'
import Course from '@/components/calendars_sessions/sessions/courses/Course.vue'
import StoreItem from '@/components/calendars_sessions/stores/StoreItem.vue'
import HourlyCourses from '@/components/calendars_sessions/sessions/courses/HourlyCourses'
import LongCourses from '@/components/calendars_sessions/sessions/courses/LongCourses'
import { groupMethods } from '@/components/mixins/group'
import { courseMethods } from '@/components/mixins/course'
import { mapGetters } from 'vuex'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import { dateRange, dateString, dateTimeRange, dateTimeString } from '@/utilities/dateFormatters'
import { currency } from '@/utilities/stringFormatters'

export default {
  name: 'Session',
  components: {
    HourlyGroups,
    CsCourse: Course,
    HourlyCourses,
    LongCourses,
    NotCourse: StoreItem
  },
  mixins: [groupMethods, courseMethods],
  data () {
    return {
      loading: true,
      longCourse: 2
    }
  },
  created () {},
  computed: {
    ...mapGetters({
      year: 'year',
      program: 'program',
      session: 'selectedSession',
      registration: 'registration',
      status: 'sessionStatus',
      students: 'students',
      loggedIn: 'loggedIn'
    }),
    closedCourses () {
      return this.session.courses.filter(course => {
        console.log('Session', this.session.name, course.state)
        return course.state === 'CLOSED'
      })
    },
    openCourses () {
      return this.session.courses.filter(course => {
        console.log('Session', this.session.name, course.state)
        return course.state !== 'CLOSED'
      })
    },
    hasSessionLateFee: function () {
      return this.session.session_late_fee > 0
    },
    hasActiveSessionLateFee: function () {
      if (!isEmpty(this.session.session_late_fee_date)) {
        const lateFeeDate = moment(this.session.session_late_fee_date)
        return lateFeeDate.isBefore(moment())
      } else {
        const lateFeeDate = moment(this.session.startDate)
        return lateFeeDate.isBefore(moment())
      }
    },
    hasEarlyDiscount: function () {
      if (this.session.early_discount_amount > 0) {
        return true
      } else if (this.session.early_discount_percent > 0) {
        return true
      }
      return false
    },
    hasGroups: function () {
      if (typeof this.session.course_blocks === 'undefined') {
        return false
      }
      const len = Object.keys(this.session.course_blocks).length
      return len > 0
    },
    courses: function () {
      if (typeof this.session.courses !== 'undefined') {
        return this.session.courses.filter(course => {
          return !course.not_course
        })
      }
      return []
    },
    hasCourses: function () {
      return this.courses.length > 0
    },
    groupsByStartTime: function () {
      const result = groupBy(this.groupsWithStudents, function (group) {
        return group.start_time
      })
      return result
    },
    groupsWithStudents: function () {
      if (typeof this.session.course_blocks !== 'undefined') {
        var blocks = this.session.course_blocks
        if (
          typeof this.students === 'undefined' ||
            this.students.length === 0
        ) {
          return blocks
        }
        const vm = this
        var result = this.session.course_blocks.filter(function (block) {
          return vm.hasStudentsWhoCanEnrollInGroup(block, vm.students)
        })
        return result
      }
      return []
    },
    coursesByStartTime: function () {
      const courses = this.coursesWithStudents
      const long = this.longCourse
      const shortCourses = courses.filter(function (course) {
        const start = moment(course.startTime)
        const end = moment(course.endTime)
        const diff = end.diff(start, 'minutes') / 60
        return diff <= long
      })
      const result = groupBy(shortCourses, function (course) {
        return course.startTime
      })
      return result
    },
    coursesWithStudents: function () {
      if (typeof this.courses !== 'undefined') {
        var courses = this.courses
        const students = this.students
        if (typeof students === 'undefined' || students.length === 0) {
          return courses
        }
        const vm = this
        var result = courses.filter(function (course) {
          return vm.hasStudentsWhoCanEnrollInCourse(course, students)
        })
        return result
      }
      return []
    },
    longCourses: function () {
      const courses = this.coursesWithStudents
      const long = this.longCourse
      var result = courses.filter(function (course) {
        const start = moment(course.startTime)
        const end = moment(course.endTime)
        const diff = end.diff(start, 'minutes') / 60
        return diff > long
      })
      return result
    },
    notCourses: function () {
      if (typeof this.session.courses !== 'undefined') {
        var courses = this.session.courses
        var result = courses.filter(function (course) {
          return course.not_course
        })
        return result
      }
      return []
    }
  },
  methods: {
    currency,
    dateRange,
    dateTimeRange,
    dateTimeString,
    dateString
  }
}
</script>

<style scoped></style>
