<template>
  <div>
    <slot></slot>
    <div class="d-flex justify-content-around" v-if="students">
      <template v-for="student in students">
        <item-cart-button
            :item="item"
            :student="student"
            :key="`item_${item.id}_enroll_${student.id}`"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { tShirtMixin } from '@/components/mixins/tShirtMixin'
import isEmpty from 'lodash/isEmpty'
import ItemCartButton from '@/components/calendars_sessions/stores/ItemCartButton.vue'
// given a item this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'ItemCartButtons',
  mixins: [tShirtMixin],
  components: {
    ItemCartButton

  },
  props: {
    item: { type: Object, required: true },
    students: {
      type: [Object, Array],
      default: function () {
        return {}
      }
    },
    enrolled: {
      type: Object,
      required: false
    },
    message: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  created () {
    this.program_slug = this.program.slug
  },
  mounted () {},
  methods: {
    isEmpty,
    // @vuese
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    didMount (e) {
      console.log('didMount', e)
    },
    addedToCart (payload) {
      this.$emit('added-to-cart', payload)
    },
    enrolledCourse (payload) {
      this.$emit('enrolled-course', payload)
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      store: 'selectedStore',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'gteManager'
    })
  }
}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}

.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}

</style>
