import { API } from '@/utilities/http-common'

const setTShirtSize = (studentId, registrationId, size) => {
  const data = {
    registration_id: registrationId,
    student_id: studentId,
    size: size
  }
  console.log('data', data)
  return API.put('registrations/set-size', data).then(response => {
    if (response.status === 200) {
      const data = response.data
      return data.result
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}
const tShirtSizes =
  [
    { id: 'cxs', size: 'Child X-SM' },
    { id: 'cs', size: 'Child SM' },
    { id: 'cm', size: 'Child MD' },
    { id: 'cl', size: 'Child LG' },
    { id: 'xs', size: 'Adult X-SM' },
    { id: 's', size: 'Adult SM' },
    { id: 'm', size: 'Adult M' },
    { id: 'l', size: 'Adult L' },
    { id: 'xl', size: 'Adult XL' },
    { id: 'xxl', size: 'Adult XXL' },
    { id: 'xxxl', size: 'Adult XXXL' }
  ]

export { tShirtSizes, setTShirtSize }
