<template>
  <div id="hourly-courses" class="row w-100">
    <div v-for="(courses, time) in hourly" class="col-12 py-2" :key="time">
      <div
        v-bind:id="courseByTimeID(time)"
        :class="{
          'border-summer': !lunchTime(time),
          'border-danger': hasLunchGaps && lunchTime(time)
        }"
        class="card"
      >
        <div
          :class="{
            'bg-summer': !lunchTime(time),
            'bg-danger': hasLunchGaps && lunchTime(time)
          }"
          class="card-header"
        >
          Starting at {{ timeString(time) }}
        </div>
        <div v-if="lunchTime(time)" class="list-group list-group-flush">
          <div class="list-group-item list-group-item-info">
            <h4>Lunch Supervision</h4>
            <p>
              If you sign your students up for classes that end at
              {{ timeString(time) }} and classes that start at
              {{ endTime(time) }}
            </p>
          </div>
          <div
            v-if="hasLunchGaps"
            class="list-group-item list-group-item-danger"
          >
            <p>
              {{ lunchGapStudents | toList }} may need to still have lunch
              supervision signed up for
            </p>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div
              v-for="course in courses"
              class="col-xl-4 col-lg-6"
              :key="course.id"
            >
              <app-course :course="course"></app-course>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Course from '@/components/calendars_sessions/sessions/courses/Course'
import { mapGetters } from 'vuex'
import moment from 'moment'

import { timeString } from '@/utilities/dateFormatters'
import isNil from 'lodash/isNil'

export default {
  name: 'HourlyCourses',
  props: ['hourly'],
  components: {
    appCourse: Course
  },
  computed: {
    ...mapGetters({
      program: 'program',
      session: 'selectedSession',
      registration: 'registration',
      status: 'sessionStatus',
      students: 'students',
      loggedIn: 'loggedIn'
    }),
    hasLunchGaps: function () {
      const registration = this.registration
      const session = this.session
      if (registration.has_lunch_gap > 0) {
        if (!isNil(registration.lunch_gaps)) {
          if (!isNil(registration.lunch_gaps[session.id])) {
            return true
          }
        }
      }
      return false
    },
    lunchGapStudents: function () {
      const registration = this.registration
      const session = this.session
      if (registration.has_lunch_gap > 0) {
        if (!isNil(registration.lunch_gaps)) {
          if (!isNil(registration.lunch_gaps[session.id])) {
            const students = registration.lunch_gaps[session.id].students
            const names = []
            for (const key in students) {
              if (Object.prototype.hasOwnProperty.call(students, key)) {
                names.push(students[key].name)
              }
            }
            return names
          }
        }
      }
      return []
    }
  },
  methods: {
    timeString,
    endTime: function (time) {
      const endTime = moment(time)
        .add(1, 'h')
        .format('h:mma')
      return endTime
    },
    courseByTimeID: function (time) {
      return 'course-by-time-'.concat(
        moment(time)
          .hour()
          .toString()
      )
    },
    lunchTime: function (time) {
      if (this.session.lunch_required) {
        const lunchStart = moment(this.session.lunch_start)
        const start = moment(time)
        if (lunchStart.isSame(start, 'hour')) {
          return true
        }
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped></style>
