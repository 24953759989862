<template>
  <div class="py-2">
    <div
        class="card"
    >
      <template v-if="typeof item.image !== 'undefined' &&
            typeof item.image.standard !== 'undefined'">
        <img v-responsive.md.lg.xl
            v-bind:src="item.image.standard"
            v-bind:alt="item.image.description"
             @click="showImage = true"
            class="card-image-top mx-auto"/>
        <v-dialog
            v-model="showImage"
            transition="slide-up"
            bg-transition="fade"
            no-scroll
            :classes="{ root: 'modal', content: 'modal-content w-75 h-75 overflow-auto' }">

                <div class="modal-header">
                  <h5 class="modal-title" v-html="item.name"/>
                  <button @click="showImage = false"
                      type="button"
                          class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <img :src="item.image.path" class="img-fluid" :alt="item.image.description">
                </div>
                <div class="modal-footer">
                  <a :href="item.image.path"
                     class="btn btn-info"
                     target="_blank">Open in tab</a>
                  <button @click="showImage = false"
                      type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
        </v-dialog>
      </template>
      <div
          class="card-header d-flex justify-content-between align-items-center">
        <strong v-html="item.name"/><span
          v-if="isManager && !item.public"
          class="small badge badge-warning">Private</span>
        <cs-button
            @click.prevent="showBrief = !showBrief"
            v-if="hasInfo"
            class="btn btn-info"
            type="info"
            icon="info">
          <font-awesome-icon icon="info"/>
        </cs-button>
      </div>
      <transition name="fade" key="item.id">
        <div v-if="showBrief" class="card-body" :class="infoStyle">
          <span v-html="info"></span>
          <button
              v-if="hasMore"
              v-on:click="showMore = !showMore"
              class="btn btn-info">
            More...
          </button>
          <div v-if="showMore"><span v-html="item.description"></span></div>
        </div>
      </transition>
      <div class="list-group list-group-flush">
        <div v-if="blockEnrollmentWithUnpaid"
            class="list-group-item list-group-item-danger">
          Purchase is blocked due to unpaid registrations
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <span v-if="item.fee > 0">Cost: {{ currency(item.fee) }}</span>
        </div>
      </div>
      <template v-if="registrationOpen && loggedIn && students.length > 0">
        <div v-if="isManager"
             class="card-footer p-0"
             :class="{ 'bg-danger-light': item.space < spaceDanger }">
          <p v-if="item.space === 0" class="text-danger">
            Out of stock: Managers can forcibly order.
          </p>
          <item-buy
              v-if="isManager"
              :item="item"
              :students="studentsWhoCanEnroll"
              :enrolled-label="'purchased'"
          />
        </div>
        <div v-else
             class="card-footer p-0"
             :class="{ 'bg-danger-light': item.space < spaceDanger }">
          <p v-if="item.space === 0" class="text-danger">
            Out of stock.
          </p>
          <item-buy
              v-else-if="!blockEnrollmentWithUnpaid"
              :item="item"
              :students="studentsWhoCanEnroll"
              :enrolled-label="'purchased'"
              class="list-group list-group-flush"/>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import moment from 'moment'
import { courseMethods } from '@/components/mixins/course'
import { VDialog } from 'vuetensils/src/components'
import { dateTimeString, humanizeTime, timeString, timeRange } from '@/utilities/dateFormatters'
import { currency } from '@/utilities/stringFormatters'
import ItemBuy from '@/components/calendars_sessions/stores/ItemBuy.vue'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
export default {
  name: 'StoreItem',
  mixins: [courseMethods],
  components: {
    ItemBuy,
    VDialog
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showBrief: false,
      showMore: false,
      infoStyle: '',
      showImage: false
    }
  },
  mounted: function () {
    if (typeof this.item.show_info !== 'undefined') {
      switch (this.item.show_info) {
        case 'brief':
          this.showBrief = true
          this.showMore = false
          break
        case 'description':
          this.showBrief = true
          this.showMore = true
          break
        default:
          this.showBrief = false
          this.showMore = false
      }
    }
    if (
      typeof this.item.style_info !== 'undefined' &&
        this.item.style_info !== 'default'
    ) {
      this.infoStyle = 'alert alert-' + this.item.style_info
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'loggedIn',
      students: 'students',
      enrolled_courses: 'enrolledCourses',
      registration: 'registration',
      store: 'selectedStore',
      member: 'member',
      isManager: 'isProgramManager',
      blockEnrollmentWithUnpaid: 'blockEnrollmentWithUnpaid'
    }),
    /**
     * this is needed for the course computed properties
     * @return Object
     */
    course () {
      return this.item
    },
    /**
     *
     * @return {any}
     */
    session () {
      return this.store
    },
    studentsWhoCanEnroll: function () {
      return this.studentsWhoCanEnrollInCourse(this.item, this.students)
    },
    showWaitlist () {
      if (this.isManager && this.item.waitlist !== 'none') {
        return true
      } else if (!this.blockEnrollmentWithUnpaid && this.loggedIn && this.item.space <= 1) {
        return true
      }
      return false
    },
    spaceWarning: function () {
      if (this.item.space_warning_ct === 0) {
        return false
      }
      return (
        this.item.space > this.item.space_danger_ct && this.item.space <= this.item.space_warning_ct
      )
    },
    spaceDanger: function () {
      if (this.item.space_danger_ct === 0 && this.item.space > 0) {
        return false
      }
      return this.item.space <= this.item.space_danger_ct
    },
    hasInfo: function () {
      return (
        !isEmpty(this.item.brief) || !isEmpty(this.item.description)
      )
    },
    hasMore: function () {
      if (this.hasInfo && this.info !== this.item.description) {
        return true
      }
      return false
    },
    info: function () {
      if (!isNil(this.item)) {
        if (!isEmpty(this.item.brief)) {
          return this.item.brief
        } else if (!isEmpty(this.item.description)) {
          return this.item.description
        }
      }
      return ''
    },
    hasLateFee: function () {
      if (this.registrationOpen) {
        if (this.session.late_fee > 0) {
          const now = moment()
          const lateFeeDate = this.item.late_fee_date
          if (now.isSameOrAfter(lateFeeDate)) {
            return true
          }
        }
      }
      return false
    },
    registrationOpen: function () {
      if (this.students != null && this.students.length >= 1) {
        if (
          typeof this.registration !== 'undefined' &&
            this.registration != null
        ) {
          if (
            this.session.registrationOpen ||
              (this.session.earlyRegistrationOpen && this.memberGte('staff')) ||
              this.item.state === 'OPEN' ||
              this.isManager
          ) {
            if (this.item.state !== 'CLOSED') {
              return !(!this.isManager && this.registration.closed)
            }
          }
        }
      }
      return false
    }
  },
  methods: {
    dateTimeString,
    timeRange,
    timeString,
    humanizeTime,
    currency
  }

}
</script>

<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
</style>
