<template>
  <div id="long-courses" class="row w-100">
    <div class="col-12 py-2">
      <div class="card">
        <div class="card-header bg-primary">
          Special Programs (classes longer then {{ longCourse }}
          {{ plural("hour", longCourse) }})
        </div>
        <div class="card-body">
          <div class="row">
            <div
              v-for="course in courses"
              class="col-xl-4 col-lg-6"
              :key="course.id"
            >
              <app-course :course="course"></app-course>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Course from '@/components/calendars_sessions/sessions/courses/Course'
import { plural } from '@/utilities/stringFormatters'

export default {
  name: 'LongCourses',
  props: ['courses', 'longCourse'],
  components: {
    appCourse: Course
  },
  methods: {
    plural
  }
}
</script>

<style scoped></style>
