<template>
  <cs-button
      v-if="isStudentEnrolled && allowChange"
      :loading="$wait.is(waitDelete)"
      @click.prevent="dropEnrolled()"
      class="btn btn-danger"
      title="Drop selected time"
      icon-left="drop"
  >Cancel for {{ student.first }}
  </cs-button>
  <cs-button
      v-else-if="item.public && !blockEnrollmentWithUnpaid"
      @handle-click="doEnrollWithBalance"
      type="primary"
      icon-left="enroll"
      :loading="isAdding"
      title="Since you have a registration balance greater then the item cost you can purchase directly"
  >
    <slot>
      Purchase for {{ student.first }}
    </slot>
  </cs-button>

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ADD_TO_REGISTRATION_BALANCE, UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import { isEmpty, isNull } from 'lodash/core'
import moment from 'moment'

// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'ItemBuyButton',
  mixins: [courseMethods],
  props: {
    student: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    bulk: {
      type: Boolean,
      required: false,
      default: false
    },
    enrolled: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      studentSelections: []
    }
  },
  created () {
    this.program_slug = this.program.slug
  },
  mounted () {},
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      registrationStatus: 'registrationStatus',
      store: 'selectedStore',
      students: 'students',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'gteManager',
      blockEnrollmentWithUnpaid: 'blockEnrollmentWithUnpaid'
    }),
    session () {
      return this.store
    },
    course () {
      return this.item
    },
    isAdding () {
      return this.$wait.is(this.waitAdd) || this.registrationStatus === 'updating'
    },
    isStudentEnrolled () {
      return this.isStudentEnrolledInCourse(this.item, this.student)
    },
    isStudentEnrolledEmit () {
      if (this.isStudentEnrolled) {
        if (!this.item.public) {
          this.$emit('privateEnrolled')
        }
        return true
      } else {
        return false
      }
    },
    isDropped () {
      const enrolled = this.enrolled_course
      if (!isEmpty(enrolled) && enrolled.dropped) {
        return true
      } else {
        return false
      }
    },
    waitAdd () {
      return `cart.add.${this.item.id}.student.${this.student.id}`
    },
    waitDelete () {
      return `cart.delete.${this.item.id}.student.${this.student.id}`
    },
    canEnrollWithBalance () {
      return this.registrationBalance < 0 && this.registrationBalance + this.fees <= 0
    },
    registrationBalance () {
      return this.registration.balance
    },
    fees () {
      return this.item.fee + this.item.materialFee
    }
  },
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),

    doEnrollWithBalance () {
      this.$wait.start(this.waitAdd)
      if (this.isManager) {
        this.enroll(this.student)
            .then(data => {
              console.log('didEnrollWithBalance.data', data)
              this.$wait.end(this.waitAdd)
              this.$emit('did-enroll', this.student, this.item)

            })
            .catch((error) => {
              console.error('didEnrollWithBalance.data', error)
              this.$wait.end(this.waitAdd)
              this.$emit('fail-enroll', this.student, this.item)
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.student.name + ' problem',
                text:
                    this.student.name +
                    ' problem purchasing for student in ' + this.item.name,
                duration: 2000,
                speed: 1000
              })
            })
      } else {
        this.enrollCourseWithBalance(this.student)
            .then(data => {
              console.log('didEnrollWithBalance.data', data)
              this.$wait.end(this.waitAdd)
              this.$emit('did-enroll', this.student, this.item)
            })
            .catch((error) => {
              console.error('didEnrollWithBalance.data', error)
              this.$wait.end(this.waitAdd)
              this.$emit('fail-enroll', this.student, this.item)
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.student.name + ' problem',
                text:
                    this.student.name +
                    ' problem purchasing for student in ' + this.item.name,
                duration: 2000,
                speed: 1000
              })
            })
      }

    },
    addCourseToCart () {
      this.disabled = true
      this.addToCart(this.student)
        .then(() => {
        })
        .catch(() => {
          this.disabled = false
        })
    },
    removeCourseFromCart () {
      console.warn('cart', this.cart, this.item)

      this.addToCart(this.student)
        .then(() => {
        })
        .catch(() => {
          this.disabled = false
        })
    },
    dropEnrolled () {
      this.$wait.start(this.waitDelete)
      var enrolled = this.enrolled_course
      this.disabled = true
      this.drop(enrolled, this.item, this.student)
        .then(function () {
          this.$wait.end(this.waitDelete)
        })
        .catch(function () {
          this.$wait.end(this.waitDelete)
        })
    }
  }

}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}

.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}
</style>
