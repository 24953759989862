<template>
  <div>
    <slot></slot>
    <div class="d-flex justify-content-around" v-if="students">
      <template v-for="student in students">
        <course-cart-button
            v-if="isStandardCourse"
            :course="course"
            :student="student"
            :key="`course_${course.id}_enroll_${student.id}`"
        />

      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'

import TShirtPicker from '@/components/t_shirts/TShirtPicker'
import { tShirtMixin } from '@/components/mixins/tShirtMixin'
import isEmpty from 'lodash/isEmpty'
import { VDialog } from 'vuetensils/src/components'
import CourseCartButton from '@/components/calendars_sessions/sessions/courses/CourseCartButton'
import CourseHourlyButton from '@/components/calendars_sessions/sessions/courses/CourseHourlyButton'
// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'CourseCartButtons',
  mixins: [tShirtMixin],
  components: {
    CourseHourlyButton,
    CourseCartButton,
    TShirtPicker,
    VDialog
  },
  props: {
    course: { type: Object, required: true },
    students: {
      type: [Object, Array],
      default: function () {
        return {}
      }
    },
    enrolled: {
      type: Object,
      required: false
    },
    message: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      studentForTShirt: null,
      courseForTShirt: null,
      showModal: false
    }
  },
  created () {
    this.program_slug = this.program.slug
  },
  mounted () {},
  methods: {
    isEmpty,
    // @vuese
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    didMount (e) {
      console.log('didMount', e)
    },
    addedToCart (payload) {
      this.checkTShirt(payload.student, payload.course)
      this.$emit('added-to-cart', payload)
    },
    enrolledCourse (payload) {
      this.checkTShirt(payload.student, payload.course)
      this.$emit('enrolled-course', payload)
    },
    checkTShirt (student, course) {
      console.log('checkTShirt', student, course, this.hasSize(student.id))
      if (course.include_t_shirt) {
        console.log('checkTShirt includes')
        if (!this.hasSize(student.id)) {
          console.log('checkTShirt includes noSize')
          this.studentForTShirt = student
          this.showModal = true
        }
      }
    },
    hideModal () {
      this.showModal = false
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'gteManager'
    }),
    isStandardCourse () {
      return this.course.hourly_fee === 0 || (!this.course.allow_start && !this.course.allow_end)
    }
  }
}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}

.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}

</style>
