<template>
  <div :id="course.slug" class="py-2">
    <div
        class="card"
        :class="{
        'border-danger': spaceDanger,
        'border-warning': spaceWarning,
        'border-notice': isClosed
      }"
    >
      <template v-if="typeof course.image !== 'undefined' &&
            typeof course.image.standard !== 'undefined'">
        <img v-responsive.md.lg.xl
            v-bind:src="course.image.standard"
            v-bind:alt="course.image.description"
             @click="showImage = true"
            class="card-image-top mx-auto"/>
        <v-dialog
            v-model="showImage"
            transition="slide-up"
            bg-transition="fade"
            no-scroll
            :classes="{ root: 'modal', content: 'modal-content w-75 h-75 overflow-auto' }"
        >

                <div class="modal-header">
                  <h5 class="modal-title" v-html="course.name"/>
                  <button @click="showImage = false"
                      type="button"
                          class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <img :src="course.image.path" class="img-fluid" :alt="course.image.description">
                </div>
                <div class="modal-footer">
                  <a :href="course.image.path"
                     class="btn btn-info"
                     target="_blank">Open in tab</a>
                  <button @click="showImage = false"
                      type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>

        </v-dialog>
      </template>
      <div
          class="card-header d-flex justify-content-between align-items-center"
          :class="{
          'bg-danger': spaceDanger || blockEnrollmentWithUnpaid,
          'bg-warning': spaceWarning,
          'bg-notice': isClosed
        }"
      >
        <strong v-html="course.name"/><span
          v-if="isManager && !course.public"
          class="small badge badge-warning"
      >Private</span>
        <button
            @click.prevent="showBrief = !showBrief"
            v-if="hasInfo && !isClosed"
            class="btn btn-info"
        >
          <font-awesome-icon icon="info"/>
        </button>
      </div>
      <transition name="fade" key="course.id">
        <div v-if="showBrief" class="card-body" :class="infoStyle">
          <span v-html="info"></span>
          <button
              v-if="hasMore  && !isClosed"
              v-on:click="showMore = !showMore"
              class="btn btn-info"
          >
            More...
          </button>
          <div v-if="showMore"><span v-html="course.description"></span></div>
        </div>
      </transition>
      <div class="list-group list-group-flush">
        <div v-if="course.include_t_shirt" class="list-group-item list-group-item-success">
          Includes T-Shirt <small>(you may be asked to pick a t-shirt size)</small>
        </div>
        <div v-if="hasLateFee" class="list-group-item list-group-item-warning">
          Enrollments after {{ humanizeTime(course.late_fee_date) }} on
          {{ dateString(course.late_fee_date,'M/d') }} will be assessed a
          {{ currency(session.late_fee) }} late registration fee.
        </div>
        <div v-if="isManager" class="list-group-item list-group-item-program d-flex justify-content-between">
          <span><strong>Space:</strong> {{ course.space }}</span>
          <span><strong>Enrolled:</strong> {{ course.enrolled }}</span>
          <span><strong>In Cart:</strong> {{ course.cart_ct }}</span>
          <span><strong>Max:</strong> {{ course.maxStudents }}</span>
        </div>
        <div
            v-if="course.space <= 0"
            class="list-group-item list-group-item-danger"
        >
          This class is full
        </div>
        <div
            v-else-if="blockEnrollmentWithUnpaid"
            class="list-group-item list-group-item-danger"
        >
          New enrollments are blocked due to unpaid registrations
        </div>
        <div
            v-else-if="spaceDanger"
            class="list-group-item list-group-item-danger"
        >
          This class is almost full, remember that is not enrolled until you
          have paid for it.
          <span v-if="course.show_space_remaining">({{ course.space}}/{{course.maxStudents}} spaces)</span>
        </div>
        <div
            v-if="spaceWarning"
            class="list-group-item list-group-item-warning"
        >
          This class is approaching capacity, remember that is not enrolled
          until you have paid for it.
          <span v-if="course.show_space_remaining">({{ course.space}}/{{course.maxStudents}} spaces)</span>
        </div>

        <div
            class="list-group-item  d-flex justify-content-between align-items-center"
        >
          <span title="grades for class">{{ course.grade_list }}</span
          ><span
        >{{ timeRange(course.startTime,course.endTime ) }}</span
        ><span>{{ course.days }}</span>
        </div>
        <div
            class="list-group-item  d-flex justify-content-between align-items-center"
        >
          <span v-if="course.fee > 0">Fee: {{ currency(course.fee) }}</span>
          <span v-if="course.hourly_fee > 0"
          >Hourly: {{ currency(course.hourly_fee) }}/hr</span
          >
          <span v-if="course.materialFee > 0"
          >Materials: {{ currency(course.materialFee) }}</span
          >
        </div>
      </div>
      <template v-if="registrationOpen && loggedIn && students.length > 0  && !isClosed">
        <course-enroll
            v-if="!isManager && !blockEnrollmentWithUnpaid"
            :course="course"
            :students="studentsWhoCanEnroll"
            class="list-group list-group-flush"/>
        <div v-else
             class="card-footer p-0"
             :class="{ 'bg-danger-light': course.space < spaceDanger }">
          <p v-if="course.space <= 0 && isManager" class="text-danger">
            Class is full: Managers can forcibly enroll.
          </p>
          <enroll-buttons
              v-if="loggedIn && (course.space > 0 || isManager)"
              :course="course"
              :students="students"
          />
          <waitlist-buttons
              v-if="showWaitlist"
              :course="course"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import EnrollButtons from '@/components/shared/EnrollButtons.vue'
import WaitlistButtons from '@/components/shared/WaitlistButtons'
import { mapGetters } from 'vuex'

import { courseMethods } from '@/components/mixins/course'
import CourseEnroll from '@/components/calendars_sessions/sessions/courses/CourseEnroll'
import { VDialog } from 'vuetensils/src/components'
import { dateTimeString, humanizeTime, timeString, timeRange, dateString } from '@/utilities/dateFormatters'
import { currency } from '@/utilities/stringFormatters'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import parseISO from 'date-fns/parseISO'
import isAfter from 'date-fns/isAfter'
export default {
  name: 'Course',
  mixins: [courseMethods],
  components: {
    CourseEnroll,
    EnrollButtons,
    WaitlistButtons,
    VDialog
  },
  props: ['course'],
  data () {
    return {
      showBrief: false,
      showMore: false,
      infoStyle: '',
      showImage: false
    }
  },
  mounted: function () {
    if (typeof this.course.show_info !== 'undefined') {
      switch (this.course.show_info) {
        case 'brief':
          this.showBrief = true
          this.showMore = false
          break
        case 'description':
          this.showBrief = true
          this.showMore = true
          break
        default:
          this.showBrief = false
          this.showMore = false
      }
    }
    if (
      typeof this.course.style_info !== 'undefined' &&
        this.course.style_info !== 'default'
    ) {
      this.infoStyle = 'alert alert-' + this.course.style_info
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'loggedIn',
      students: 'students',
      enrolled_courses: 'enrolledCourses',
      registration: 'registration',
      session: 'selectedSession',
      member: 'member',
      isManager: 'isProgramManager',
      blockEnrollmentWithUnpaid: 'blockEnrollmentWithUnpaid'
    }),
    isClosed () {
      return this.course.state === 'CLOSED'
    },
    studentsWhoCanEnroll: function () {
      return this.studentsWhoCanEnrollInCourse(this.course, this.students)
    },
    showWaitlist () {
      if (this.isManager && this.course.waitlist !== 'none') {
        return true
      } else if (!this.blockEnrollmentWithUnpaid && this.loggedIn && this.course.space <= 1) {
        return true
      }
      return false
    },
    spaceWarning: function () {
      if (this.course.space_warning_ct === 0) {
        return false
      }
      return (
        this.course.space > this.course.space_danger_ct && this.course.space <= this.course.space_warning_ct
      )
    },
    spaceDanger: function () {
      if (this.course.space_danger_ct === 0 && this.course.space > 0) {
        return false
      }
      return this.course.space <= this.course.space_danger_ct
    },
    hasInfo: function () {
      return (
        !isEmpty(this.course.brief) || !isEmpty(this.course.description)
      )
    },
    hasMore: function () {
      if (this.hasInfo && this.info !== this.course.description) {
        return true
      }
      return false
    },
    info: function () {
      if (!isNil(this.course)) {
        if (!isEmpty(this.course.brief)) {
          return this.course.brief
        } else if (!isEmpty(this.course.description)) {
          return this.course.description
        }
      }
      return ''
    },
    hasLateFee: function () {
      if (this.registrationOpen) {
        if (this.session.late_fee > 0) {
          const now = new Date()
          const lateFeeDate = parseISO(this.course.late_fee_date)
          if (isAfter(lateFeeDate, now)) {
            return true
          }
        }
      }
      return false
    },
    registrationOpen: function () {
      if (this.students != null && this.students.length >= 1) {
        if (
          typeof this.registration !== 'undefined' &&
            this.registration != null
        ) {
          if (
            this.session.registrationOpen ||
              (this.session.earlyRegistrationOpen && this.memberGte('staff')) ||
              this.course.state === 'OPEN' ||
              this.isManager
          ) {
            if (this.course.state !== 'CLOSED') {
              return !(!this.isManager && this.registration.closed)
            }
          }
        }
      }
      return false
    }
  },
  methods: {
    dateTimeString,
    dateString,
    timeRange,
    timeString,
    humanizeTime,
    currency
  }

}
</script>

<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
</style>
